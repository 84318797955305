<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="copyTradeModal"
      :title="$t('equity_bond.titles.copy_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.copy_trade_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      :no-enforce-focus="true"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row>
                <b-card :title="$t('equity_bond.titles.order_details')">
                  <b-row>
                    <!-- order date-->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_date')"
                          rules="required"
                        >
                          <flat-pickr
                            :placeholder="
                              $t('equity_bond.trade_form.order_date')
                            "
                            v-model="tradeForm.orderDate"
                            onClose="testClose"
                            class="form-control "
                            :config="orderDateConfig"
                            :state="errors.length > 0 ? false : null"
                            @on-change="checkOrdersDate('Order Date')"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order time-->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_time')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_time')"
                          rules="required|min:8"
                        >
                          <cleave
                            id="orderTime"
                            v-model="tradeForm.orderTime"
                            class="form-control "
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t('equity_bond.trade_form.order_time')
                            "
                            :state="errors.length > 0 ? false : null"
                            @blur.native="checkTime('Order Time')"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Order duration date -->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('equity_bond.trade_form.order_duration_date')
                        "
                        v-if="orderDurationInputsVisible"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('equity_bond.trade_form.order_duration_date')
                          "
                          :rules="
                            tradeForm.timeInForce == 'GTD' ? 'required' : ''
                          "
                        >
                          <flat-pickr
                            :placeholder="
                              $t('equity_bond.trade_form.order_duration_date')
                            "
                            v-model="tradeForm.orderDurationDate"
                            onClose="testClose"
                            class="form-control "
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                            :disabled="orderDurationDataDisabled"
                            @on-change="checkOrdersDate('Order Duration Date')"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Order duration time -->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('equity_bond.trade_form.order_duration_time')
                        "
                        v-if="orderDurationInputsVisible"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('equity_bond.trade_form.order_duration_time')
                          "
                          :rules="
                            tradeForm.timeInForce == 'GTD' ? 'required' : ''
                          "
                        >
                          <cleave
                            id="orderDurationTime"
                            v-model="tradeForm.orderDurationTime"
                            class="form-control "
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t('equity_bond.trade_form.order_duration_time')
                            "
                            :state="errors.length > 0 ? false : null"
                            @blur.native="
                              checkOrdersTime('Order Duration Time')
                            "
                            :disabled="orderDurationTimeDisabled"
                          />

                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- entry type -->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.entry_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.entry_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_entry_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @change="checkAmendEntryType()"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.buy_sell')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.buy_sell')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_buy_sell')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_order_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.time_in_force')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.time_in_force')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_time_in_force')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="changeTimeInForceType"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty order date-->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_order_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_date')"
                          :rules="'required'"
                        >
                          <flat-pickr
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_order_date')
                            "
                            v-model="tradeForm.cptyOrderDate"
                            onClose="testClose"
                            class="form-control "
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                            @on-change="
                              checkTraSettlCptyDate('Cpty Order Date')
                            "
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty order time-->
                    <b-col cols="6" xl="1" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_order_time')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_time')"
                          rules="required|min:8"
                        >
                          <cleave
                            id="orderTime"
                            v-model="tradeForm.cptyOrderTime"
                            class="form-control "
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_order_time')
                            "
                            :state="errors.length > 0 ? false : null"
                            @blur.native="checkTime('Execution Time')"
                          />

                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Price -->
                    <b-col
                      cols="6"
                      xl="2"
                      lg="2"
                      md="3"
                      sm="6"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_price')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_price')"
                          :rules="
                            tradeForm.orderType == 'Stop'
                              ? 'required|min_value:0'
                              : '' || tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopPrice"
                            class="form-control "
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_price')
                            "
                            @blur.native="changeStopPrice(tradeForm.stopPrice)"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trailing Amount -->
                    <b-col
                      cols="6"
                      xl="2"
                      lg="2"
                      md="3"
                      sm="6"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.trailing_amount')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.trailing_amount')"
                          :rules="
                            tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-form-input
                            id="trailingAmount"
                            v-model="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.trailing_amount')
                            "
                            @blur.native="
                              changeTrailingAmount(tradeForm.trailingAmount)
                            "
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- /******************************* OCO Orders***************************************************************** */ -->

                  <b-row>
                    <!-- enable connected OCO orders-->
                    <b-col cols="12" xl="2" lg="2" md="12" sm="12">
                      <b-form-group class="mt-1">
                        <validation-provider
                          #default="{ errors }"
                          name="enable OCO orders"
                        >
                          <b-form-checkbox
                            v-model="tradeForm.enableConnectedOCOOrders"
                            plain
                            @change="changeEnableOCOOrder()"
                          >
                            {{ $t("equity_bond.trade_form.enable_oco") }}
                          </b-form-checkbox>
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty profit limit & cpty stop loss stop & cpty stop loss limit -->

                    <!-- cpty Take Profit Limit -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                          )
                        "
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                            )
                          "
                          :rules="
                            tradeForm.enableConnectedOCOOrders
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="cptyTakeProfitLimit"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.cptyTakeProfitLimit"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                              )
                            "
                            @blur.native="
                              checkEnableOCOCptyInputs('takeProfitLimit')
                            "
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty Stop Loss Stop -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                          )
                        "
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                            )
                          "
                        >
                          <cleave
                            id="cptystopLossStop"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.cptyStopLossStop"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                              )
                            "
                            @blur.native="
                              checkEnableOCOCptyInputs('stopLossStop')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty Stop Loss Limit -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                          )
                        "
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                            )
                          "
                        >
                          <cleave
                            id="cptyStopLossLimit"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.cptyStopLossLimit"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                              )
                            "
                            @blur.native="
                              checkEnableOCOCptyInputs('stopLossLimit')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- client profit limit & client stop loss stop & client stop loss limit -->
                  <b-row>
                    <!-- Take Profit Limit -->
                    <b-col offset-xl="2" cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.take_profit_limit')"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.take_profit_limit')"
                          :rules="
                            tradeForm.enableConnectedOCOOrders
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="takeProfitLimit"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.takeProfitLimit"
                            class="form-control "
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.take_profit_limit')
                            "
                            @blur.native="
                              checkEnableOCOInputs('takeProfitLimit')
                            "
                          />

                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Loss Stop -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_loss_stop')"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_loss_stop')"
                          :rules="
                            tradeForm.enableConnectedOCOOrders
                              ? 'min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="stopLossStop"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopLossStop"
                            class="form-control "
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_loss_stop')
                            "
                            @blur.native="checkEnableOCOInputs('stopLossStop')"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Loss Limit -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_loss_limit')"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_loss_limit')"
                          :rules="
                            tradeForm.enableConnectedOCOOrders
                              ? 'min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="stopLossLimit"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopLossLimit"
                            class="form-control "
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_loss_limit')
                            "
                            @blur.native="checkEnableOCOInputs('stopLossLimit')"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>
              <b-row>
                <!-- /******************************* Trade Date & Time && Security Details***************************************************************** */ -->

                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Security Details***************************************************************** */ -->

                  <b-card :title="$t('equity_bond.titles.security_details')">
                    <b-row>
                      <!-- cost center -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cost_center')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- security desc-->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.security_desc')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.security_desc')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.securityDesc"
                              :options="optSecurityDescriptions"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_security_desc'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingSecurityDesc"
                              @option:selecting="selectedSecuritydesc"
                            />
                            <!-- @search="
                              (search, loading) => {
                                loading(false);
                                searchSecuritydesc(search);
                              }
                            " -->
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- ISIN -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.isin')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="ISIN"
                          >
                            <b-form-input
                              id="isin"
                              v-model="tradeForm.isin"
                              :name="$t('equity_bond.trade_form.isin')"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('equity_bond.trade_form.isin')"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- quantity -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.quantity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.quantity')"
                            rules="required|min_value:0"
                          >
                            <cleave
                              id="quantity"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.quantity"
                              class="form-control "
                              :raw="true"
                              :options="cleaveQuantityOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.quantity')
                              "
                              @blur.native="refreshAmount(true)"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client price -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_price')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_price')"
                            rules="required|min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPrice"
                              class="form-control "
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_price')
                              "
                              @blur.native="refreshAmount(true)"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client price type ccy -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.price_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.price_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientPriceType"
                              :options="optClientPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.price_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- amount -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.amount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.amount')"
                          >
                            <cleave
                              id="amount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.amount"
                              class="form-control "
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="$t('equity_bond.trade_form.amount')"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- exchange traded -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.exchange_traded')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.exchange_traded')"
                            :rules="'required'"
                          >
                            <div class="d-flex align-items-center">
                              <v-select
                                v-model="tradeForm.exchangeTraded"
                                :options="optExchangeTrades"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_exchange_traded'
                                  )
                                "
                                :reduce="(val) => val"
                                :taggable="true"
                                :loading="exchangeSelectBoxLoading"
                                @option:created="createNewExchangeTraded"
                                style="width:100%"
                              />
                              <i
                                class="fa-solid fa-circle-info fa-xs input-dropdown animate__animated animate__fadeIn"
                                style="padding-left:0.2rem"
                                v-b-tooltip.hover.top="
                                  'After entering the exchange traded you want to add, press enter.'
                                "
                              ></i>
                            </div>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- client -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client')"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client')"
                              rules="required"
                              style="width: 100%;"
                            >
                              <v-select
                                v-model="tradeForm.client"
                                :options="optClients"
                                :clearable="false"
                                :placeholder="
                                  $t('equity_bond.trade_form.select_client')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingClient"
                                :filterable="true"
                                @option:selecting="selectedClient"
                              />

                              <small class="text-danger ">{{
                                errors[0]
                              }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedClients.length > 0"
                            >
                              <template #button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item @click="seeFilteredClientModal">
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_account')"
                            rules="required"
                          >
                            <b-form-input
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.client_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counter party -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.counterparty')"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.counterparty')"
                              style="width: 100%;"
                            >
                              <v-select
                                v-model="tradeForm.counterparty"
                                :options="optCounterParties"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_counterparty'
                                  )
                                "
                                :reduce="(val) => val"
                                :loading="loadingCounterparty"
                                :filterable="true"
                                @option:selecting="selectedCounterparty"
                              />

                              <small class="text-danger ">{{
                                errors[0]
                              }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedCounterparties.length > 0"
                            >
                              <template #button-content>
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item
                                @click="seeFilteredCounterpartyModal"
                              >
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cpty_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cpty_account')"
                          >
                            <b-form-input
                              id="isin"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.cpty_account')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counterparty price -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.counterparty_price')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.counterparty_price')
                            "
                          >
                            <cleave
                              id="counterpartyPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.counterpartyPrice"
                              class="form-control "
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.counterparty_price')
                              "
                              @blur.native="calculateCptyPrice"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counter party trader -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              label="Trader"
                              @option:created="
                                createNewCounterpartyTrader($event)
                              "
                              taggable
                              ref="counterpartyTraderSelect"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sfc user -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.sfc_user')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.scfUser"
                              :options="optScfUsers"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.acting_capacity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.acting_capacity')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.actingCapaticy"
                              :options="optActingCapacities"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Clean-->
                      <b-col cols="6" xl="1" lg="1" md="4" sm="6" class="mt-1">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="clean"
                          >
                            <b-form-checkbox
                              v-model="tradeForm.clean"
                              plain
                              class="compact_form_checkbox"
                            >
                              {{ $t("equity_bond.trade_form.clean") }}
                            </b-form-checkbox>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- accrued -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.accrued') + '*'"
                          v-if="tradeForm.clean"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.accrued')"
                            :rules="tradeForm.clean ? 'required' : ''"
                          >
                            <cleave
                              id="accrued"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.accrued"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.accrued')
                              "
                              @blur.native="calculateAccrued"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Trade Date & Time***************************************************************** */ -->
                  <b-card :title="$t('equity_bond.titles.trade_date_time')">
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="4" xl="3" lg="3" md="5" sm="4">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.trade_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.trade_date')"
                          >
                            <b-input-group
                              class="compact_form_input-group"
                              :class="
                                tradeForm.tradeDateName == 'Sunday' ||
                                tradeForm.tradeDateName == 'Saturday'
                                  ? 'tradeDateName'
                                  : ''
                              "
                              :append="tradeForm.tradeDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('equity_bond.trade_form.trade_date')
                                "
                                v-model="tradeForm.tradeDate"
                                onClose="testClose"
                                class="form-control "
                                :config="tradeDateConfig"
                                @on-change="checkTraSettlCptyDate('Trade Date')"
                              />
                            </b-input-group>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- T+ -->
                      <b-col
                        cols="4"
                        xl="1"
                        lg="1"
                        md="2"
                        sm="4"
                        style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                        v-if="tradeForm.entryType == 'Execution'"
                      >
                        <b-form-group label="T+">
                          <validation-provider
                            #default="{ errors }"
                            name="T+"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="T+"
                              v-model.number="tradeForm.Tplus"
                              name="T+"
                              placeholder="T+"
                              @change="arrangeTPlus"
                              type="number"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Settlement Date -->
                      <b-col
                        cols="4"
                        xl="3"
                        lg="3"
                        md="5"
                        sm="4"
                        v-if="tradeForm.entryType == 'Execution'"
                      >
                        <b-form-group
                          :label="$t('equity_bond.trade_form.settlement_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="'settlement_date'"
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-input-group
                              class="compact_form_input-group"
                              :class="
                                tradeForm.settlementDateName == 'Sunday' ||
                                tradeForm.settlementDateName == 'Saturday'
                                  ? 'settlementDateName'
                                  : ''
                              "
                              :append="tradeForm.settlementDateName"
                            >
                              <flat-pickr
                                id="settlement_date"
                                name="settlement_date"
                                :placeholder="
                                  $t('equity_bond.trade_form.settlement_date')
                                "
                                v-model="tradeForm.settlementDate"
                                onClose="testClose"
                                class="form-control "
                                :config="tradeDateConfig"
                                @on-change="
                                  checkTraSettlCptyDate('Settlement Date')
                                "
                              />
                            </b-input-group>
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="6"
                        sm="6"
                        v-if="tradeForm.entryType == 'Execution'"
                      >
                        <b-form-group
                          :label="$t('equity_bond.trade_form.execution_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.execution_time')"
                            rules="required|min:8"
                          >
                            <cleave
                              id="executionTime"
                              v-model="tradeForm.executionTime"
                              class="form-control "
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('equity_bond.trade_form.execution_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Execution Time')"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty execution time -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.cpty_execution_time')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.cpty_execution_time')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution'
                                ? 'required|min:8'
                                : ''
                            "
                          >
                            <cleave
                              id="cptyExecutionTime"
                              v-model="tradeForm.cptyExecutionTime"
                              class="form-control "
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('equity_bond.trade_form.cpty_execution_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                changeCptyExecutionTime(
                                  tradeForm.cptyExecutionTime
                                )
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <!-- /******************************* Trade Details && Settlement Details ***************************************************************** */ -->

                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <!-- /******************************* Trade Details***************************************************************** */ -->

                  <b-card :title="$t('equity_bond.titles.trade_details')">
                    <b-row>
                      <!-- Client Rate -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_rate')"
                        >
                          <b-input-group class="compact_form_input-group">
                            <b-input-group-prepend is-text>
                              <b-form-checkbox
                                plain
                                name="checkbox-input"
                                v-model="tradeForm.calculateCommissionFromRate1"
                                v-b-tooltip.hover.top
                                :title="
                                  $t(
                                    'equity_bond.trade_form.client_rate_tooltip'
                                  )
                                "
                              >
                              </b-form-checkbox>
                            </b-input-group-prepend>
                            <validation-provider
                              #default="{ errors }"
                              :name="'clientRate'"
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.calculateCommissionFromRate1
                                  ? 'required'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientRate"
                                name="clientRate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientRate"
                                class="form-control form-input-group "
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.client_rate')
                                "
                                :disabled="
                                  !tradeForm.calculateCommissionFromRate1
                                "
                                @blur.native="calculateClientRateToComission"
                              />
                              <small class="text-danger ">{{
                                errors[0]
                              }}</small>
                            </validation-provider>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <!-- Client Commission -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_comission')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.client_comission')
                            "
                            :rules="
                              tradeForm.entryType == 'Execution' &&
                              !tradeForm.calculateCommissionFromRate1
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="clientCommission"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientCommission"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_comission')
                              "
                              :disabled="tradeForm.calculateCommissionFromRate1"
                              @blur.native="calculateClientRate"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Client amount -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_amount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_amount')"
                            rules="min_value:0"
                          >
                            <cleave
                              id="clientAmmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientAmount"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_amount')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Counterparty Rate -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.counterparty_rate')
                          "
                        >
                          <b-input-group class="compact_form_input-group">
                            <b-input-group-prepend is-text>
                              <b-form-checkbox
                                plain
                                name="checkbox-input"
                                v-model="tradeForm.calculateCommissionFromRate2"
                                v-b-tooltip.hover.top
                                :title="
                                  $t(
                                    'equity_bond.trade_form.counterparty_rate_tooltip'
                                  )
                                "
                              />
                            </b-input-group-prepend>
                            <validation-provider
                              #default="{ errors }"
                              :name="'counterpartyRate'"
                              :rules="
                                tradeForm.entryType == 'Execution' &&
                                tradeForm.calculateCommissionFromRate2
                                  ? 'required'
                                  : ''
                              "
                            >
                              <cleave
                                id="counterpartyRate"
                                name="counterpartyRate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyRate"
                                class="form-control form-input-group"
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.counterparty_rate')
                                "
                                :disabled="
                                  !tradeForm.calculateCommissionFromRate2
                                "
                                @blur.native="
                                  calculateCounterpartyRateToComission
                                "
                              />

                              <small class="text-danger ">{{
                                errors[0]
                              }}</small>
                            </validation-provider>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <!-- cpty comission -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cpty_comission')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cpty_comission')"
                            :rules="
                              tradeForm.entryType == 'Execution' &&
                              !tradeForm.calculateCommissionFromRate2
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cptyComission"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyCommission"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.cpty_comission')
                              "
                              :disabled="tradeForm.calculateCommissionFromRate2"
                              @blur.native="calculateCounterpartyRate"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counterparty amount -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.counterparty_amount')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.counterparty_amount')
                            "
                            rules="min_value:0"
                          >
                            <cleave
                              id="counterpartyAmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.counterpartyAmount"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.counterparty_amount')
                              "
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- profit -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.profit')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.profit')"
                          >
                            <cleave
                              id="profit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.profit"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.profit')"
                              :disabled="true"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken via -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_taken_via')"
                          >
                            <v-select
                              v-model="tradeForm.orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                              @option:created="
                                createNewIntermediary($event, 'TAKEN')
                              "
                              taggable
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order given through -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              v-model="tradeForm.orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                              @option:created="
                                createNewIntermediary($event, 'GIVEN')
                              "
                              taggable
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.note')"
                          >
                            <b-form-textarea
                              id="form-textarea"
                              :placeholder="$t('equity_bond.trade_form.note')"
                              rows="3"
                              no-resize
                              v-model="tradeForm.note"
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->
                  <b-card :title="$t('equity_bond.titles.settlement_details')">
                    <b-row>
                      <!-- UTI  -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group :label="$t('equity_bond.trade_form.uti')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.uti')"
                          >
                            <b-form-input
                              id="UTI"
                              v-model="tradeForm.uti"
                              name="UTI"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('equity_bond.trade_form.uti')"
                              :disabled="true"
                            />

                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.unique_link_id')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.unique_link_id')"
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.unique_link_id')
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Trade Ref -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.trade_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.trade_ref')"
                          >
                            <b-form-input
                              :disabled="true"
                              id="tradeRef"
                              v-model="tradeForm.tradeRef"
                              name="tradeRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.trade_ref')
                              "
                            />
                            <small class="text-danger ">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /><b>{{
                $t("equity_bond.overlay_messages.copy_message")
              }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- trade actions -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pl-0 d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("equity_bond.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="focus"
              id="actions-popover"
            >
              <template #title>
                {{ $t("equity_bond.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>
          </b-col>

          <!-- trade ok operation buttons -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pr-0 d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="float-right mr-1"
              @click="resetTradeModal"
            >
              {{ $t("equity_bond.modals.modal_close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mr-1"
              @click="tradeOkOperation"
            >
              {{ $t("equity_bond.modals.copy_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** amend for trades modal*/}}
    </b-modal>
    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import bondStoreModule from "./BondStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
    AppTimeline,
    AppTimelineItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(EQUITYBONDS_APP_STORE_MODULE_NAME, bondStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      allEquityBondsData: [],
      copyTradeModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      actionsData: null,
      checkVerify: false,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      exchangeSelectBoxLoading: false,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optInstrumentTypes: ["EQ", "FI"],
      optSecurityDescriptions: [],
      optClientPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT", "MATCHED PRINCIPAL"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      searchedSecurityDesc: [],
      fetchedTradeData: {},
      oldTradeFormData: {},
      checkedHolidayDate: null,
      commissions: [],
      tradeForm: {
        costCenter: null,
        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        cptyTakeProfitLimit: null,
        cptyStopLossStop: null,
        cptyStopLossLimit: null,
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        tradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        Tplus: null,
        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
        static: true,
      },

      tradeDateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
        static: true,
        wrap: true,
      },
      orderDateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
        static: true,
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          // novos
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
          // fim novos
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      cleave8DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 8,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      cleaveQuantityOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal: false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchBond"]),
    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(
              this.$t("equity_bond.messages.user_error_no_users")
            );
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getActingCapacities() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getActingCapacities`)
          .then((res) => {
            if (res.data.length > 0) {
              this.optActingCapacities = res.data.map((x) => x.ActingCapacity);
            }
          })
          .catch((error) => reject(error));
      });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage("Not found GivenThrough & orderTakenVia datas");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));

      return diff;
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("EquityBond", "Save").then((res) => {
            if (res.data.valid) {
              this.tradeModalLoading = true;

              //set and check trade data
              let tradeData = this.setTradeData();

              store
                .dispatch("equity&bond/copySelectedTrade", {
                  auth: this.user,
                  tradeData: tradeData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.copyTradeModal = false;
                    this.$emit("copiedEquityBond", true);
                    this.copiedTradeMessage();
                    this.resetTradeModal();
                    this.tradeModalLoading = false;
                  } else {
                    this.errorMessage(res.data.message);
                    this.tradeModalLoading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.copyTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        }
      });
    },
    createNewExchangeTraded(value) {
      this.exchangeSelectBoxLoading = true;

      if (this.tradeForm.securityDesc != null && value.length > 2) {
        axiosIns
          .post(`saveExchange`, {
            security: this.tradeForm.securityDesc,
            exchange: value.toUpperCase().trim(),
          })
          .then((res) => {
            if (res.data.info == "success") {
              this.optExchangeTrades.push(value);
              this.tradeForm.exchangeTraded = value;
              this.exchangeSelectBoxLoading = false;
            } else {
              this.exchangeSelectBoxLoading = false;
              this.wrongToastMessage(
                this.$t("equity_bond.messages.already_exist_exchange")
              );
            }
          })
          .catch((error) => reject(error));
      } else {
        this.exchangeSelectBoxLoading = false;
        this.wrongToastMessage("Please select security description first.");
      }
    },
    searchSecuritydesc(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingSecurityDesc = true;
          let securityTypeVal = "Bond";

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchSecuritydesc`, {
                auth: this.user,
                search: search,
                type: securityTypeVal,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optSecurityDescriptions = [];
                  this.searchedSecurityDesc = [];
                  this.searchedSecurityDesc = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optSecurityDescriptions.push(data.SecurityTicker);
                  });

                  this.loadingSecurityDesc = false;
                } else {
                  this.notFoundMessage("security desc.");
                  this.loadingSecurityDesc = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedSecuritydesc(search) {
      this.tradeForm.isin = null;
      this.tradeForm.clientPriceType = null;
      this.tradeForm.exchangeTraded = null;

      let selecting = this.searchedSecurityDesc.find(
        (s) => s.SecurityTicker === search
      );
      if (selecting) {
        this.tradeForm.isin = selecting.SecurityIsin;
        this.tradeForm.clientPriceType = selecting.SecurityCcy;
        this.tradeForm.quantity = selecting.DefaultShares;
      }
      this.refreshAmount(true);

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedSecuritydescForExchange`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              // this.tradeForm.exchangeTraded = res.data.resData.Exchange;
              this.optExchangeTrades = [];
              if (res.data.resData.length > 0) {
                res.data.resData.forEach((element) => {
                  if (element != null) {
                    this.optExchangeTrades.push(element);
                  }
                });
              }
              this.checkCommission();
            } else {
              this.optExchangeTrades = [];
            }
          })
          .catch((error) => reject(error));
      });
    },

    searchClient(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingClient = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchClient`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  // this.optClients = [];
                  this.searchedClients = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optClients.push(data.AccountName);
                  });

                  this.loadingClient = false;
                } else {
                  this.notFoundMessage("Client");
                  this.loadingClient = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedClient(search) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
      this.checkCommission();
    },
    checkCommission() {
      if (
        this.tradeForm.client != null &&
        this.tradeForm.securityDesc != null
      ) {
        if (this.tradeForm.clientAccount && this.tradeForm.securityDesc) {
          let client = this.searchedClients.find(
            (s) => s.AccountNumber === this.tradeForm.clientAccount
          );

          let security = this.searchedSecurityDesc.find(
            (s) => s.SecurityTicker == this.tradeForm.securityDesc
          );

          let commissionRate = this.commissions.find(
            (s) =>
              s.Client == client.AccountName && s.Country == security.Country
          );
          if (commissionRate) {
            this.tradeForm.calculateCommissionFromRate1 = true;
            this.tradeForm.clientRate = commissionRate.Rate * 10000;
          } else {
            // this.tradeForm.clientRate = null;
          }
        } else {
          this.tradeForm.clientRate = null;
        }
      }

      if (
        this.tradeForm.counterparty != null &&
        this.tradeForm.securityDesc != null
      ) {
        if (this.tradeForm.cptyAccount && this.tradeForm.securityDesc) {
          let client = this.searchedClients.find(
            (s) => s.AccountNumber === this.tradeForm.cptyAccount
          );

          let security = this.searchedSecurityDesc.find(
            (s) => s.SecurityTicker == this.tradeForm.securityDesc
          );

          let commissionRate = this.commissions.find(
            (s) =>
              s.Client == client.AccountName && s.Country == security.Country
          );
          if (commissionRate) {
            this.tradeForm.calculateCommissionFromRate2 = true;
            this.tradeForm.counterpartyRate = commissionRate.Rate * 10000;
          } else {
            // this.tradeForm.counterpartyRate = null;
          }
        } else {
          this.tradeForm.counterpartyRate = null;
        }
      }
    },
    selectedClientFromTable(client) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName;
      this.tradeForm.clientAccount = client.AccountNumber;
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },
    searchCounterparty(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingCounterparty = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchCounterparty`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optCounterParties = [];
                  this.searchedCounterparties = [];

                  this.searchedCounterparties = res.data.resData;

                  if (res.data.resData.length > 0) {
                    res.data.resData.forEach((data) => {
                      this.optCounterParties.push(data.AccountName);
                    });
                  }

                  this.loadingCounterparty = false;
                } else {
                  this.notFoundMessage("Counterparty");
                  this.loadingCounterparty = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedCounterparty(search) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedCounterparties.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;
      this.checkCommission();
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },
    selectedCounterpartyFromTable(cpty) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName;
      this.tradeForm.cptyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
            }
            this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });
    },
    createNewCounterpartyTrader(trader) {
      if (this.tradeForm.counterparty) {
        if (trader && trader.length > 2) {
          return new Promise((resolve, reject) => {
            axiosIns
              .post(`createNewCounterpartyForTrader`, {
                auth: this.user,
                counterparty: this.tradeForm.counterparty,
                trader: trader,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.tradeForm.counterpartyTrader = trader;
                  this.successMessage(
                    "Counterparty Trader created successfully"
                  );
                  this.optCounterPartyTraders.push(trader);
                } else {
                  this.wrongToastMessage(res.data.message);
                }
              })
              .catch((error) => reject(error));
          });
        }
      } else {
        this.tradeForm.counterpartyTrader = null;

        this.wrongToastMessage("Please select a counterparty first");
      }
    },
    createNewIntermediary(intermediary, direction) {
      if (intermediary && intermediary.length > 2) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`createNewIntermediary`, {
              intermediary: {
                title: intermediary,
                direction: direction,
              },
            })
            .then((res) => {
              if (res.data.info) {
                if (direction === "GIVEN") {
                  this.tradeForm.orderGivenThrough = intermediary;
                  this.optOrderGivenThroughs.push(intermediary);
                } else {
                  this.tradeForm.orderTakenVia = intermediary;
                  this.optOrderTakenVias.push(intermediary);
                }
                this.successMessage("Intermediary created successfully");
              } else {
                this.wrongToastMessage(res.data.message);
              }
            })
            .catch((error) => reject(error));
        });
      } else {
        this.wrongToastMessage("Please enter a valid intermediary name");
      }
    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },
    checkEnableOCOInputs(input) {
      this.convertNumberInputFormat();
      if (input == "takeProfitLimit") {
        if (this.tradeForm.takeProfitLimit) {
          if (this.tradeForm.takeProfitLimit > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.stopLossStop > 0) {
                if (
                  this.tradeForm.takeProfitLimit < this.tradeForm.stopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_lower_stop_price"
                    )
                  );
                  this.tradeForm.takeProfitLimit = null;
                }
              }
              if (
                this.tradeForm.takeProfitLimit > 0 &&
                this.tradeForm.stopLossLimit > 0
              ) {
                if (
                  this.tradeForm.takeProfitLimit < this.tradeForm.stopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_lower_limit_price"
                    )
                  );
                  this.tradeForm.takeProfitLimit = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.stopLossStop > 0) {
                if (
                  this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_greater_stop_price"
                    )
                  );
                  this.tradeForm.takeProfitLimit = null;
                }
              }
              if (
                this.tradeForm.takeProfitLimit > 0 &&
                this.tradeForm.stopLossLimit > 0
              ) {
                if (
                  this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_greater_limit_price"
                    )
                  );
                  this.tradeForm.takeProfitLimit = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_take_profit"
              )
            );
          }
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.stopLossStop) {
          this.tradeForm.stopLossLimit = null;
          if (this.tradeForm.stopLossStop > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.takeProfitLimit > 0) {
                if (
                  this.tradeForm.stopLossStop > this.tradeForm.takeProfitLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_greater_profit_price"
                    )
                  );
                  this.tradeForm.stopLossStop = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.takeProfitLimit > 0) {
                if (
                  this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_lower_profit_price"
                    )
                  );
                  this.tradeForm.stopLossStop = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_stop"
              )
            );
          }
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.stopLossLimit) {
          this.tradeForm.stopLossStop = null;
          if (this.tradeForm.stopLossLimit > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.takeProfitLimit > 0) {
                if (
                  this.tradeForm.stopLossLimit > this.tradeForm.takeProfitLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_limit_greater_profit_price"
                    )
                  );
                  this.tradeForm.stopLossLimit = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.takeProfitLimit > 0) {
                if (
                  this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_limit_lower_profit_price"
                    )
                  );
                  this.tradeForm.stopLossLimit = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_limit"
              )
            );
          }
        }
      }
      this.arrangeInputFormat();
    },
    checkEnableOCOCptyInputs(input) {
      this.convertNumberInputFormat();
      if (input == "takeProfitLimit") {
        if (this.tradeForm.cptyTakeProfitLimit) {
          if (this.tradeForm.cptyTakeProfitLimit > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.cptyStopLossStop > 0) {
                if (
                  this.tradeForm.cptyTakeProfitLimit <
                  this.tradeForm.cptyStopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_lower_stop_price"
                    )
                  );
                  this.tradeForm.cptyTakeProfitLimit = null;
                }
              }
              if (
                this.tradeForm.cptyTakeProfitLimit > 0 &&
                this.tradeForm.cptyStopLossLimit > 0
              ) {
                if (
                  this.tradeForm.cptyTakeProfitLimit <
                  this.tradeForm.cptyStopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_lower_limit_price"
                    )
                  );
                  this.tradeForm.cptyTakeProfitLimit = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.cptyStopLossStop > 0) {
                if (
                  this.tradeForm.cptyTakeProfitLimit >
                  this.tradeForm.cptyStopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_greater_stop_price"
                    )
                  );
                  this.tradeForm.cptyTakeProfitLimit = null;
                }
              }
              if (
                this.tradeForm.cptyTakeProfitLimit > 0 &&
                this.tradeForm.cptyStopLossLimit > 0
              ) {
                if (
                  this.tradeForm.cptyTakeProfitLimit >
                  this.tradeForm.cptyStopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.take_profit_greater_limit_price"
                    )
                  );
                  this.tradeForm.cptyTakeProfitLimit = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_take_profit"
              )
            );
          }
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.cptyStopLossStop) {
          this.tradeForm.cptyStopLossLimit = null;
          if (this.tradeForm.cptyStopLossStop > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.cptyTakeProfitLimit > 0) {
                if (
                  this.tradeForm.cptyStopLossStop >
                  this.tradeForm.cptyTakeProfitLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_greater_profit_price"
                    )
                  );
                  this.tradeForm.cptyStopLossStop = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.cptyTakeProfitLimit > 0) {
                if (
                  this.tradeForm.cptyTakeProfitLimit >
                  this.tradeForm.cptyStopLossStop
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_lower_profit_price"
                    )
                  );
                  this.tradeForm.cptyStopLossStop = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_stop"
              )
            );
          }
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.cptyStopLossLimit) {
          this.tradeForm.cptyStopLossStop = null;
          if (this.tradeForm.cptyStopLossLimit > 0) {
            if (this.tradeForm.buySell == "Buy") {
              if (this.tradeForm.cptyTakeProfitLimit > 0) {
                if (
                  this.tradeForm.cptyStopLossLimit >
                  this.tradeForm.cptyTakeProfitLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_limit_greater_profit_price"
                    )
                  );
                  this.tradeForm.cptyStopLossLimit = null;
                }
              }
            } else if (this.tradeForm.buySell == "Sell") {
              if (this.tradeForm.cptyTakeProfitLimit > 0) {
                if (
                  this.tradeForm.cptyTakeProfitLimit >
                  this.tradeForm.cptyStopLossLimit
                ) {
                  this.checkedEnableOCOInputsMessage(
                    this.$t(
                      "fx_spot_forward_swap.messages.stop_loss_limit_lower_profit_price"
                    )
                  );
                  this.tradeForm.cptyStopLossLimit = null;
                }
              }
            }
          } else {
            this.greaterThanZeroMessage(
              this.$t(
                "fx_spot_forward_swap.messages.greater_then_zero_stop_loss_limit"
              )
            );
          }
        }
      }
      this.arrangeInputFormat();
    },

    changeEnableOCOOrder() {
      if (!this.tradeForm.enableConnectedOCOOrders) {
        this.tradeForm.takeProfitLimit = null;
        this.tradeForm.stopLossLimit = null;
        this.tradeForm.stopLossStop = null;
        this.tradeForm.cptyTakeProfitLimit = null;
        this.tradeForm.cptyStopLossLimit = null;
        this.tradeForm.cptyStopLossStop = null;
      }
    },

    convertNumberInputFormat() {
      if (this.tradeForm.counterpartyRate) {
        this.tradeForm.counterpartyRate = Number(
          this.tradeForm.counterpartyRate
        );
      }
      if (this.tradeForm.clientRate) {
        this.tradeForm.clientRate = Number(this.tradeForm.clientRate);
      }

      if (this.tradeForm.amount) {
        this.tradeForm.amount = Number(this.tradeForm.amount);
      }

      if (this.tradeForm.clientCommission) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        );
      }
      if (this.tradeForm.clientPrice && this.tradeForm.clientPrice > 0) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice);
      }
      if (this.tradeForm.cptyCommission) {
        this.tradeForm.cptyCommission = Number(this.tradeForm.cptyCommission);
      }
      if (this.tradeForm.accrued) {
        this.tradeForm.accrued = Number(this.tradeForm.accrued);
      }

      if (this.tradeForm.counterpartyPrice) {
        this.tradeForm.counterpartyPrice = Number(
          this.tradeForm.counterpartyPrice
        );
      }
      if (this.tradeForm.counterpartyAmount) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        );
      }
      if (this.tradeForm.clientAmount) {
        this.tradeForm.clientAmount = Number(this.tradeForm.clientAmount);
      }
      if (this.tradeForm.stopLossLimit) {
        this.tradeForm.stopLossLimit = Number(this.tradeForm.stopLossLimit);
      }
      if (this.tradeForm.stopLossStop) {
        this.tradeForm.stopLossStop = Number(this.tradeForm.stopLossStop);
      }
      if (this.tradeForm.takeProfitLimit) {
        this.tradeForm.takeProfitLimit = Number(this.tradeForm.takeProfitLimit);
      }
      if (this.tradeForm.stopPrice) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice);
      }

      if (this.tradeForm.cptyTakeProfitLimit) {
        this.tradeForm.cptyTakeProfitLimit = Number(
          this.tradeForm.cptyTakeProfitLimit
        );
      }
      if (this.tradeForm.cptyStopLossLimit) {
        this.tradeForm.cptyStopLossLimit = Number(
          this.tradeForm.cptyStopLossLimit
        );
      }
      if (this.tradeForm.cptyStopLossStop) {
        this.tradeForm.cptyStopLossStop = Number(
          this.tradeForm.cptyStopLossStop
        );
      }
    },
    arrangeInputFormat() {
      if (this.tradeForm.clientPrice && this.tradeForm.clientPrice > 0) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice).toFixed(
          8
        );
      }

      if (this.tradeForm.clientRate) {
        this.tradeForm.clientRate = Number(this.tradeForm.clientRate).toFixed(
          8
        );
      }

      if (this.tradeForm.counterpartyRate) {
        this.tradeForm.counterpartyRate = Number(
          this.tradeForm.counterpartyRate
        ).toFixed(8);
      }

      if (this.tradeForm.clientCommission) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        ).toFixed(2);
      }

      if (this.tradeForm.cptyCommission) {
        this.tradeForm.cptyCommission = Number(
          this.tradeForm.cptyCommission
        ).toFixed(2);
      }

      if (this.tradeForm.amount) {
        this.tradeForm.amount = Number(this.tradeForm.amount).toFixed(8);
      }

      if (this.tradeForm.counterpartyPrice) {
        this.tradeForm.counterpartyPrice = Number(
          this.tradeForm.counterpartyPrice
        ).toFixed(8);
      }
      if (this.tradeForm.counterpartyAmount) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount) {
        this.tradeForm.clientAmount = Number(
          this.tradeForm.clientAmount
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossLimit) {
        this.tradeForm.stopLossLimit = Number(
          this.tradeForm.stopLossLimit
        ).toFixed(8);
      }
      if (this.tradeForm.stopLossStop) {
        this.tradeForm.stopLossStop = Number(
          this.tradeForm.stopLossStop
        ).toFixed(8);
      }
      if (this.tradeForm.takeProfitLimit) {
        this.tradeForm.takeProfitLimit = Number(
          this.tradeForm.takeProfitLimit
        ).toFixed(8);
      }

      if (this.tradeForm.cptyTakeProfitLimit) {
        this.tradeForm.cptyTakeProfitLimit = Number.parseFloat(
          this.tradeForm.cptyTakeProfitLimit
        ).toFixed(8);
      }
      if (this.tradeForm.cptyStopLossLimit) {
        this.tradeForm.cptyStopLossLimit = Number.parseFloat(
          this.tradeForm.cptyStopLossLimit
        ).toFixed(8);
      }
      if (this.tradeForm.cptyStopLossStop) {
        this.tradeForm.cptyStopLossStop = Number.parseFloat(
          this.tradeForm.cptyStopLossStop
        ).toFixed(8);
      }

      if (this.tradeForm.stopPrice) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice).toFixed(8);
      }
      if (this.tradeForm.profit) {
        this.tradeForm.profit = Number(this.tradeForm.profit).toFixed(2);
      }
      if (this.tradeForm.trailingAmount) {
        this.tradeForm.trailingAmount = Number(
          this.tradeForm.trailingAmount
        ).toFixed(2);
      }
      if (this.tradeForm.accrued) {
        this.tradeForm.accrued = Number(this.tradeForm.accrued).toFixed(2);
      }
    },
    refreshAmount(val = false) {
      this.convertNumberInputFormat();
      this.calculateClientFaceValue();
      this.calculateClientComission();
      this.calculateCounterpartyComission();
      this.calculateClientAmount();
      this.calculateCounterpartyAmount();
      this.calculateTCProfit();
      this.arrangeInputFormat();
    },

    calculateClientFaceValue() {
      if (this.tradeForm.quantity > 0 && this.tradeForm.quantity != null) {
        if (this.tradeForm.quantity.toString().indexOf(".") > -1) {
          this.tradeForm.quantity = Math.round(this.tradeForm.quantity);
        }
      }

      if (this.tradeForm.quantity > 0 && this.tradeForm.clientPrice > 0) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "FI") {
          let cal =
            (this.tradeForm.quantity * this.tradeForm.clientPrice) / 100;

          console.log(cal);
          this.tradeForm.amount = cal;
          // this.tradeForm.clientAmount = cal;
          // this.tradeForm.counterpartyAmount = cal;
        }
      }
    },

    calculateAccrued() {
      this.convertNumberInputFormat();
      this.refreshAmount(false);
      this.arrangeInputFormat();
    },
    calculateCptyPrice() {
      this.convertNumberInputFormat();
      this.refreshAmount(false);
      this.arrangeInputFormat();
    },

    calculateClientRateToComission() {
      this.convertNumberInputFormat();
      this.refreshAmount(false);
      this.arrangeInputFormat();
    },

    calculateClientComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientRate > -1
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "FI") {
          let temp = this.tradeForm.amount;

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          this.tradeForm.clientCommission = this.formatPrice2(
            (Number(temp) * Number(this.tradeForm.clientRate)) / 10000,
            2
          );
        }

        if (this.tradeForm.clientCommission > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.clientAmount + this.tradeForm.clientCommission;

          this.tradeForm.profit = this.tradeForm.clientCommission;
        }
      }
      this.arrangeInputFormat();
    },
    calculateCounterpartyRateToComission() {
      this.convertNumberInputFormat();
      this.refreshAmount(false);
      this.arrangeInputFormat();
    },
    calculateCounterpartyComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.counterpartyRate > -1
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "FI") {
          let temp = this.calculateConterpartyFaceValue();

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          this.tradeForm.cptyCommission =
            (Number(temp) * Number(this.tradeForm.counterpartyRate)) / 10000;
        }
        if (this.tradeForm.cptyCommission > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.cptyCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }

        this.arrangeInputFormat();
      }
    },
    calculateClientRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientCommission > -1
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "FI") {
          let temp = this.tradeForm.amount;

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          this.tradeForm.clientRate = this.formatPrice2(
            (this.tradeForm.clientCommission / temp) * 10000,
            8
          );
        }

        if (this.tradeForm.clientRate > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.clientAmount + this.tradeForm.clientCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }
        this.convertNumberInputFormat();
        this.calculateClientFaceValue();
        this.calculateClientAmount();
        this.calculateCounterpartyAmount();
        this.calculateTCProfit();
        this.arrangeInputFormat();
      }
    },
    calculateCounterpartyRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.cptyCommission > -1
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "FI") {
          let temp = this.calculateConterpartyFaceValue();

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = temp + this.tradeForm.accrued;
          }

          this.tradeForm.counterpartyRate = this.formatPrice2(
            (this.tradeForm.cptyCommission / temp) * 10000,
            8
          );
        }

        if (this.tradeForm.counterpartyRate > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            Number(this.tradeForm.counterpartyAmount) +
            Number(this.tradeForm.cptyCommission);
          this.tradeForm.profit =
            Number(this.tradeForm.clientCommission) +
            Number(this.tradeForm.cptyCommission) * -1;
        }
        this.convertNumberInputFormat();
        this.calculateClientFaceValue();
        this.calculateClientAmount();
        this.calculateCounterpartyAmount();
        this.calculateTCProfit();
        this.arrangeInputFormat();
      }
    },
    calculateConterpartyFaceValue() {
      if (this.tradeForm.quantity > 0) {
        this.convertNumberInputFormat();
        if (
          this.tradeForm.counterpartyPrice > 0 &&
          this.tradeForm.instrumentType == "FI"
        ) {
          this.tradeForm.amount =
            (Number(this.tradeForm.quantity) *
              Number(this.tradeForm.counterpartyPrice)) /
            100;
          //this.arrangeInputFormat()
          return (
            (Number(this.tradeForm.quantity) *
              Number(this.tradeForm.counterpartyPrice)) /
            100
          );
        } else {
          return 0;
        }
      }
    },
    calculateClientAmount() {
      let temp = 0;
      this.convertNumberInputFormat();
      if (this.tradeForm.amount > 0) {
        temp = Number(temp) + Number(this.tradeForm.amount);
      }

      if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
        if (this.tradeForm.instrumentType == "FI") {
          temp = Number(temp) + Number(this.tradeForm.accrued);
        }
      }

      if (this.tradeForm.clientCommission > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = Number(temp) + Number(this.tradeForm.clientCommission);
        } else {
          temp = Number(temp) - Number(this.tradeForm.clientCommission);
        }
      }

      if (temp != 0) {
        this.tradeForm.clientAmount = temp;
        //this.tradeForm.counterpartyAmount = temp;
        this.arrangeInputFormat();
      } else {
        this.tradeForm.clientAmount = null;
      }
    },
    calculateCounterpartyAmount() {
      let temp = 0;
      this.convertNumberInputFormat();
      temp = temp + this.calculateConterpartyFaceValue();

      if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
        if (this.tradeForm.instrumentType == "FI") {
          temp = Number(temp) + Number(this.tradeForm.accrued);
        }
      }

      if (this.tradeForm.cptyCommission > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = Number(temp) + Number(this.tradeForm.cptyCommission);
        } else {
          temp = Number(temp) - Number(this.tradeForm.cptyCommission);
        }
      }

      if (temp > 0) {
        //this.tradeForm.clientAmount = temp;
        this.tradeForm.counterpartyAmount = Number(temp);
        this.arrangeInputFormat();
      } else {
        this.tradeForm.counterpartyAmount = null;
      }
    },
    calculateTCProfit() {
      let temp = 0;
      if (
        this.tradeForm.clientAmount > 0 &&
        this.tradeForm.counterpartyAmount > 0
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.buySell == "Buy") {
          this.tradeForm.profit = this.formatPrice2(
            this.tradeForm.clientAmount - this.tradeForm.counterpartyAmount,
            2
          );
        } else {
          this.tradeForm.profit = this.formatPrice2(
            -this.tradeForm.clientAmount + this.tradeForm.counterpartyAmount,
            2
          );
        }
        this.arrangeInputFormat();
      } else {
        this.tradeForm.profit = null;
      }
    },

    getAllSecurityDesc() {
      axiosIns
        .post(`getAllVerifiedFISecurities`)
        .then((res) => {
          if (res.data.length > 0) {
            this.optSecurityDescriptions = [];
            this.searchedSecurityDesc = [];
            this.searchedSecurityDesc = res.data;
            res.data.forEach((data) => {
              this.optSecurityDescriptions.push(data.SecurityTicker);
            });
          } else {
            this.notFoundMessage("security desc.");
          }
        })
        .catch((error) => console.log(error));
    },

    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, tradeDate) {
      let checkDate = tradeDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(tradeDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    arrangeTPlus() {
      if (this.tradeForm.tradeDate && this.tradeForm.tradeDateName != null) {
        if (this.tradeForm.Tplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              if (!this.tradeForm.tradeDate) {
                return;
              }

              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;
              tradeDate.setDate(tradeDate.getDate() + period);

              this.checkHolidaysDate(holidays, tradeDate);

              //found settlement date name
              let settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (settlementDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              if (!this.tradeForm.tradeDate) {
                return;
              }

              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;

              tradeDate.setDate(tradeDate.getDate() + period);

              //found settlement date name
              let settlementDateName = this.weekday[tradeDate.getDay()];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              } else if (settlementDateName == "Sunday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              }

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(tradeDate);
              this.tradeForm.settlementDateName = this.weekday[
                tradeDate.getDay()
              ];
            }
          });
        }
      }
    },
    checkTraSettlCptyDate(type) {
      if (type == "Trade Date" && this.tradeForm.tradeDate) {
        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        //arrange as T+
        if (this.tradeForm.Tplus > 0) {
          this.arrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_before_order_date")
          );
          this.tradeForm.tradeDate = null;
        }
        if (this.tradeForm.instrumentType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "equity_bond.messages.trade_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.tradeDate = null;
          }
        }
      } else if (type == "Settlement Date" && this.tradeForm.settlementDate) {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.settlementDate)
        );

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }
        if (this.tradeForm.instrumentType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "equity_bond.messages.settlement_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.settlementDate = null;
          }
        }
      } else if ("Cpty Order Date" && this.tradeForm.cptyOrderDate) {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "equity_bond.messages.trade_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "equity_bond.messages.settlement_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }
      }
    },
    checkTime(type) {
      if (type == "Order Time") {
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.order_time_later_execution_time")
            );
            this.tradeForm.orderTime = null;
          }
        }

        this.checkOrdersTime("Order Time");
      } else if ("Execution Time") {
        if (
          this.tradeForm.executionTime != null &&
          this.tradeForm.executionTime.length < 8
        ) {
          for (let i = this.tradeForm.executionTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.executionTime += ":";
            } else if (i == 5) {
              this.tradeForm.executionTime += ":";
            } else {
              this.tradeForm.executionTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.execution_time_later_order_time")
            );
            this.tradeForm.executionTime = null;
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "equity_bond.messages.execution_time_later_counterparty_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },
    changeCptyExecutionTime() {
      for (let i = this.tradeForm.cptyExecutionTime.length; i < 8; i++) {
        if (i == 2) {
          this.tradeForm.cptyExecutionTime += ":";
        } else if (i == 5) {
          this.tradeForm.cptyExecutionTime += ":";
        } else {
          this.tradeForm.cptyExecutionTime += "0";
        }
      }
    },
    checkOrdersTime(type) {
      if (type == "Order Duration Time") {
        if (
          this.tradeForm.orderDurationTime != null &&
          this.tradeForm.orderDurationTime.length < 8
        ) {
          for (let i = this.tradeForm.orderDurationTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderDurationTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderDurationTime += ":";
            } else {
              this.tradeForm.orderDurationTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.order_duration_later_order_time")
            );
            this.tradeForm.orderDurationTime = null;
          }
        }
      } else if (type == "Order Time") {
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "equity_bond.messages.order_time_less_order_duration_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }
      }
    },
    checkOrdersDate(type) {
      if (type == "Order Date") {
        if (this.tradeForm.timeInForce == "DAY") {
          this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        }

        this.tradeForm.tradeDate = this.tradeForm.orderDate;

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_date_after_order_duration_date")
          );
          this.tradeForm.orderDate = null;
        }
      } else if (type == "Order Duration Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_duration_less_order_date")
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    changeStopPrice() {
      this.arrangeInputFormat();
    },
    changeTrailingAmount() {
      this.arrangeInputFormat();
    },
    resetTradeModal() {
      this.copyTradeModal = false;
      this.fetchedTradeData = {};
      this.oldTradeFormData = {};
      this.checkedHolidayDate = null;
      this.actionsData = [];
      this.searchedSecurityDesc = [];
      // this.searchedCounterparties = []
      // this.searchedClients = []
      this.checkVerify = false;
      ////
      this.tradeForm = {
        costCenter: null,

        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderTime: moment().format("HH:mm:ss"),
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        cptyTakeProfitLimit: null,
        cptyStopLossStop: null,
        cptyStopLossLimit: null,
        instrumentType: "FI",
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: "PRINCIPAL",
        tradeDate: null,
        tradeDateName: null,
        settlementDate: null,
        settlementDateName: null,
        Tplus: null,
        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      };

      /////
      (this.optSecurityDescriptions = []),
        // (this.optClients = []),
        (this.optClientPriceTypes = []),
        (this.optExchangeTrades = []),
        // (this.optCounterParties = []),
        (this.optCounterPartyTraders = []),
        (this.optScfUsers = []),
        (this.optOrderTakenVias = []),
        (this.optOrderGivenThroughs = []);
    },

    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("equity_bond.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("equity_bond.messages.tc_uti_not_found"));
      }
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("equity_bond.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    successMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: val,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    copiedTradeMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("equity_bond.toast_messages.trade_copy_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatPrice2(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },
    setTradeData() {
      if (
        this.tradeForm.timeInForce == "GTC" ||
        this.tradeForm.timeInForce == "FOK" ||
        this.tradeForm.timeInForce == "FAK"
      ) {
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }

      if (!this.tradeForm.enableConnectedOCOOrders) {
        this.tradeForm.takeProfitLimit = null;
        this.tradeForm.stopLossStop = null;
        this.tradeForm.stopLossLimit = null;
        this.tradeForm.cptyTakeProfitLimit = null;
        this.tradeForm.cptyStopLossStop = null;
        this.tradeForm.cptyStopLossLimit = null;
      }

      let setTrade = {
        costCenter: this.tradeForm.costCenter,

        instrumentType: "FI",
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        executionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,
        client: this.tradeForm.client,
        account: this.tradeForm.clientAccount,
        securityDesc: this.tradeForm.securityDesc,
        isin: this.tradeForm.isin,
        buySell: this.tradeForm.buySell,
        quantity: this.tradeForm.quantity
          ? parseFloat(this.tradeForm.quantity)
          : 0,
        clientAmount:
          this.tradeForm.clientAmount != null
            ? parseFloat(this.tradeForm.clientAmount)
            : null,
        counterpartyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate)
            : null,
        comissionRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate)
            : null,
        counterpartyComission:
          this.tradeForm.cptyCommission != null
            ? parseFloat(this.tradeForm.cptyCommission)
            : null,
        commissionAmount:
          this.tradeForm.clientCommission != null
            ? parseFloat(this.tradeForm.clientCommission)
            : null,
        counterpartyAmount:
          this.tradeForm.counterpartyAmount != null
            ? parseFloat(this.tradeForm.counterpartyAmount)
            : null,
        counterpartyAccount: this.tradeForm.cptyAccount,
        counterpartyTrader: this.tradeForm.counterpartyTrader,
        counterpartyPrice:
          this.tradeForm.counterpartyPrice != null
            ? parseFloat(this.tradeForm.counterpartyPrice)
            : null,
        clean: this.tradeForm.Clean ? 1 : 0,
        accuredInterest:
          this.tradeForm.accrued != null
            ? parseFloat(this.tradeForm.accrued)
            : null,
        ccy: this.tradeForm.clientPriceType,
        price:
          this.tradeForm.clientPrice != null
            ? parseFloat(this.tradeForm.clientPrice)
            : null,
        amount:
          this.tradeForm.amount != null
            ? parseFloat(this.tradeForm.amount)
            : null,
        counterparty: this.tradeForm.counterparty,
        tcUser: this.tradeForm.scfUser,
        orderTakenVia: this.tradeForm.orderTakenVia,
        orderGivenThrough: this.tradeForm.orderGivenThrough,
        orderPrice: null,
        margin: this.tradeForm.tradeReportingNeeded ? 1 : 0,
        exchangeTraded: this.tradeForm.exchangeTraded,
        prop: this.tradeForm.prop ? 1 : 0,
        savedBy: this.user.name
          ? this.user.name + " " + this.user.surname
          : null,
        saveDate: moment().format("YYYY-MM-DD"),
        saveTime: moment().format("HH:mm:ss"),
        amendedBy: null,
        amendmentDate: null,
        amentmentTime: null,
        amendmentReason: null,
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        traderNote: this.tradeForm.note,
        securityAccount: null,
        actingCapaticy: this.tradeForm.actingCapaticy,
        counterpartOrderTime:
          this.tradeForm.cptyOrderTime != null
            ? this.timeFormat(this.tradeForm.cptyOrderTime)
            : null,
        counterpartyExecutionTime:
          this.tradeForm.cptyExecutionTime != null
            ? this.timeFormat(this.tradeForm.cptyExecutionTime)
            : null,
        tcUti: this.tradeForm.uti ?? null,
        uniqueLinkId: this.tradeForm.uniqueLinkID,
        tcProfit:
          this.tradeForm.profit != null
            ? parseFloat(this.tradeForm.profit)
            : null,
        entryType: this.tradeForm.entryType,
        orderType: this.tradeForm.orderType,
        timeInForce: this.tradeForm.timeInForce,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice)
            : null,
        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.trailingAmount)
            : null,
        takeProfit: null,
        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,
        counterpartyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,
        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,
        enableOCO:
          this.tradeForm.enableConnectedOCOOrders != null
            ? this.tradeForm.enableConnectedOCOOrders
            : false,
        ocoType: null,
        takeProfitLimit:
          this.tradeForm.takeProfitLimit != null
            ? parseFloat(this.tradeForm.takeProfitLimit)
            : null,
        stopLoss:
          this.tradeForm.stopLossStop != null
            ? parseFloat(this.tradeForm.stopLossStop)
            : null,
        stopLossLimit:
          this.tradeForm.stopLossLimit != null
            ? parseFloat(this.tradeForm.stopLossLimit)
            : null,

        cptyTakeProfitLimit: this.tradeForm.cptyTakeProfitLimit
          ? parseFloat(this.tradeForm.cptyTakeProfitLimit)
          : null,

        cptyStopLoss: this.tradeForm.cptyStopLossStop
          ? parseFloat(this.tradeForm.cptyStopLossStop)
          : null,

        cptyStopLossLimit: this.tradeForm.cptyStopLossLimit
          ? parseFloat(this.tradeForm.cptyStopLossLimit)
          : null,
        ocoLink: null,
        orderQuantity: null,
        // startTradeDate:
        //   this.tradeForm.tradeStart != null
        //     ? this.YMDdateFormat(this.tradeForm.tradeStart)
        //     : null,
        // endTradeDate:
        //   this.tradeForm.tradeEnd != null
        //     ? this.YMDdateFormat(this.tradeForm.tradeEnd)
        //     : null,
        // startSettlementDate:
        //   this.tradeForm.settleStart != null
        //     ? this.YMDdateFormat(this.tradeForm.settleStart)
        //     : null,
        // endSettlementDate:
        //   this.tradeForm.settleEnd != null
        //     ? this.YMDdateFormat(this.tradeForm.settleEnd)
        //     : null,
        // ID: 0,
      };

      return setTrade;
    },
  },
  watch: {
    copyTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeEquityBondTCUsers();
          //this.getArrangeEquityBondCurrencies();
          this.getArrangeEquityBondOrderTakenGivenThrough();
          this.getActingCapacities();

          //   this.tradeForm.scfUser = this.user.name;
          //   this.tradeForm.orderDurationDate = moment().format("DD-MM-YYYY ");
          //   this.tradeForm.orderDurationTime = moment()
          //     .endOf("day")
          //     .format("HH:mm:ss")
          //     .toString();

          //   this.tradeForm.entryType = "Order";
          //   this.tradeForm.buySell = "Buy";
          //   this.tradeForm.orderType = "Limit";
          //   this.tradeForm.timeInForce = "DAY";
          //   this.tradeForm.orderDate = moment().format("DD-MM-YYYY");
          //   this.tradeForm.orderTime = moment().format("HH:mm:ss");
          //   this.tradeForm.actingCapaticy = "PRINCIPAL";
        }
      },
    },
    "tradeForm.buySell": {
      handler: function(val, before) {
        if (before != null) {
          this.convertNumberInputFormat();
          this.calculateClientFaceValue();
          this.calculateClientComission();
          this.calculateCounterpartyComission();
          this.calculateClientAmount();
          this.calculateCounterpartyAmount();
          this.calculateTCProfit();
          ["takeProfitLimit", "stopLossStop", "stopLossLimit"].forEach((el) => {
            this.checkEnableOCOInputs(el);
          });
          ["takeProfitLimit", "stopLossStop", "stopLossLimit"].forEach((el) => {
            this.checkEnableOCOCptyInputs(el);
          });
        }
      },
      deep: true,
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter: val.CostCenter,
            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null ? val.OrderDurationTime : null,
            entryType: val.EntryType ?? null,
            buySell: val.BuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime: val.OrderTime != null ? val.OrderTime : null,
            cptyOrderDate:
              val.CounterpartyOrderDate != null
                ? this.dateFormat(val.CounterpartyOrderDate)
                : null,
            cptyOrderTime: val.CptyOrderTime != null ? val.CptyOrderTime : null,
            enableConnectedOCOOrders: val.EnableOCO ? true : false,
            stopPrice: val.StopPrice != null ? parseFloat(val.StopPrice) : null,
            trailingAmount:
              val.TrailingAmount != null
                ? parseFloat(val.TrailingAmount)
                : null,
            takeProfitLimit:
              val.TakeProfitLimit != null
                ? parseFloat(val.TakeProfitLimit)
                : null,
            stopLossStop:
              val.StopLoss != null ? parseFloat(val.StopLoss) : null,
            stopLossLimit:
              val.StopLossLimit != null ? parseFloat(val.StopLossLimit) : null,
            cptyTakeProfitLimit:
              val.CptyTakeProfitLimit != null
                ? parseFloat(val.CptyTakeProfitLimit)
                : null,
            cptyStopLossStop:
              val.CptyStopLoss != null ? parseFloat(val.CptyStopLoss) : null,
            cptyStopLossLimit:
              val.CptyStopLossLimit != null
                ? parseFloat(val.CptyStopLossLimit)
                : null,
            instrumentType: "FI",
            securityDesc: val.SecurityDescription ?? null,
            quantity: val.Quantity ?? null,
            clientPrice: val.Price != null ? parseFloat(val.Price) : null,
            clientPriceType: val.Ccy ?? null,
            amount: val.Amount != null ? parseFloat(val.Amount) : null,
            counterpartyPrice:
              val.CounterpartyPrice != null
                ? parseFloat(val.CounterpartyPrice)
                : null,
            clean: val.Clean == "0" ? false : true,
            accrued: val.AccuredInterest != null ? val.AccuredInterest : null,
            client: val.Client ?? null,
            clientAccount: val.Account ?? null,
            isin: val.Isin ?? null,
            exchangeTraded: val.ExchangeTraded ?? null,
            counterparty: val.Counterparty ?? null,
            cptyAccount: val.CounterpartyAccount ?? null,
            counterpartyTrader: val.CounterpartyTrader ?? null,
            scfUser: val.TcUser ?? null,
            actingCapaticy: val.ActingCapacity ?? null,
            tradeReportingNeeded: val.margin == "0" ? false : true,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlementDate != null
                ? this.dateFormat(val.SettlementDate)
                : null,
            executionTime: val.ExecutionTime != null ? val.ExecutionTime : null,
            cptyExecutionTime:
              val.CptyExecutionTime != null ? val.CptyExecutionTime : null,
            clientRate:
              val.CommissionRate != null
                ? parseFloat(val.CommissionRate)
                : null,
            clientCommission:
              val.CommissionAmount != null
                ? parseFloat(val.CommissionAmount)
                : null,
            clientAmount:
              val.ClientAmount != null ? parseFloat(val.ClientAmount) : null,
            counterpartyRate:
              val.CounterpartyRate != null
                ? parseFloat(val.CounterpartyRate)
                : null,
            cptyCommission:
              val.CounterpartyCommission != null
                ? parseFloat(val.CounterpartyCommission)
                : null,
            counterpartyAmount:
              val.CounterpartyAmount != null
                ? parseFloat(val.CounterpartyAmount)
                : null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            profit: val.TcProfit != null ? parseFloat(val.TcProfit) : null,
            tradeReportingNeeded: val.TradeReportingNeeded == 1 ? true : false,
            note: val.TraderNote ?? null,
            calculateCommissionFromRate1:
              val.CalculateCommissionFromRate1 == 1 ? true : false,
            calculateCommissionFromRate2:
              val.CalculateCommissionFromRate2 == 1 ? true : false,
            uti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            tradeRef: val.id,
            tradeStart: null,
            tradeEnd: null,
            settleStart: null,
            settleEnd: null,
            ocoLink: val.OCOLink ?? null,
            ocoType: val.OCOType ?? null,
            amanededBy: val.AmendedBy ?? null,
            amendmentDate: val.AmendmentDate ?? null,
            amendmentReason: val.AmendmentReason ?? null,
            amendmentTime: val.AmendmentTime ?? null,
            cancelDate: val.CancelDate ?? null,
            cancelReason: val.CancelReason ?? null,
            cancelTime: val.CancelTime ?? null,
            cancelled: val.Cancelled ?? "0",
            cancelledBy: val.CancelledBy ?? null,
          };

          this.oldTradeFormData = JSON.parse(JSON.stringify(this.tradeForm));

          this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
          this.tradeForm.orderDurationTime = moment()
            .endOf("day")
            .format("HH:mm:ss")
            .toString();

          if (isNaN(this.tradeForm.counterpartyPrice)) {
            this.tradeForm.counterpartyPrice = null;
          }

          this.changeTimeInForceType();

          //set trade date name
          let tradeDate = new Date(
            this.MDYdateFormat(this.tradeForm.tradeDate)
          );

          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];
          //set settle date name
          let settleDate = new Date(
            this.MDYdateFormat(this.tradeForm.settlementDate)
          );

          this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

          if (this.tradeForm.accrued != null) {
            this.tradeForm.clean = true;
          }

          this.tradeForm.calculateCommissionFromRate1 = true;
          this.tradeForm.calculateCommissionFromRate2 = true;

          this.arrangeInputFormat();
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}

.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.swall2-overlay {
  z-index: 100005;
}
.swal2-modal {
  z-index: 99999;
}

.form-input-group {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// .v-select::v-deep .vs__selected {
//     position: relative !important;
//   }
</style>
